import Axios from 'axios'
import VueProgressBar from 'vue-progressbar'
import VTooltip from 'v-tooltip'
import vClickOutside from 'v-click-outside'
import vuescroll from 'vuescroll'
import ToggleButton from 'vue-js-toggle-button'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import Notifications from 'vue-notification'
import ConfirmDialog from '@/components/presets/confirm-dialog'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueEllipseProgress from 'vue-ellipse-progress'
import VueGtag from 'vue-gtag'
import VueTypedJs from 'vue-typed-js'
import config from '@/config'

const { recaptchaSiteKey } = config

function initTypedJs(Vue) {
  Vue.use(VueTypedJs)
}

function initTooltip(Vue) {
  Vue.use(VTooltip, {
    defaultDelay: 400
  })
}

function initClickOutside(Vue) {
  Vue.use(vClickOutside)
}

function initScroll(Vue) {
  Vue.use(vuescroll, {
    ops: {
      rail: {
        size: '6px',
        gutterOfSide: '0px',
      },
      scrollPanel: {
        scrollingX: false,
      },
      bar: {
        keepShow: false,
        disable: false,
        background: '#4c4c4c',
        size: '4px',
      }
    }
  })
}

function initToggleButton(Vue) {
  Vue.use(ToggleButton)
}

function initConfirmDialog(Vue) {
  Vue.use(ConfirmDialog)
  Vue.component('confirm-dialog', ConfirmDialog.default)
}

function initAxios(Vue) {
  Vue.prototype.$http = Axios
}

function initCroppa(Vue) {
  Vue.use(Croppa)
}

function initProgressBar(Vue) {
  Vue.use(VueProgressBar, {
    color: '#7556b5',
    failedColor: '#ee4551',
    thickness: '5px',
    transition: {
      speed: '0.2s',
      opacity: '0.6s',
      termination: 300
    },
    autoFinish: false
  })
}

function initNotifications(Vue) {
  Vue.use(Notifications)
}

function initRecaptcha(Vue) {
  Vue.use(VueReCaptcha, {
    siteKey: recaptchaSiteKey,
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
    }
  })
}

function initEllipseProgress(Vue) {
  Vue.use(VueEllipseProgress)
}

function initGtag(Vue) {
  Vue.use(VueGtag, {
    config: { id: 'UA-155208163-1' }
  })
}

export default (Vue) => {
  initTypedJs(Vue)
  initAxios(Vue)
  initTooltip(Vue)
  initClickOutside(Vue)
  initScroll(Vue)
  initToggleButton(Vue)
  initConfirmDialog(Vue)
  initCroppa(Vue)
  initProgressBar(Vue)
  initNotifications(Vue)
  initRecaptcha(Vue)
  initEllipseProgress(Vue)
  initGtag(Vue)
}
