import _ from 'lodash'

const keyName = 'account'
const versionKeyName = 'account-version'

const migrations = [
  // 1
  function () {
    const account = localStorage.getItem(keyName)
    if (_.isNil(account)) {
      return
    }
    try {
      JSON.parse(account)
    } catch (e) {
      localStorage.removeItem(keyName)
    }
  }
]

function migrate() {
  let accountVersion = localStorage.getItem(versionKeyName)
  accountVersion = _.isNil(accountVersion) ? 0 : parseInt(accountVersion)
  let step = 0
  if (accountVersion < migrations.length) {
    for (const migration of migrations) {
      step += 1
      if (step > accountVersion) {
        migration()
        localStorage.setItem(versionKeyName, step.toString())
      }
    }
  }
}
try {
  migrate()
} catch (e) {
  console.error(e)
  localStorage.removeItem(keyName)
  localStorage.setItem(versionKeyName, migrations.length.toString())
}


export function getAccount() {
  const account = localStorage.getItem(keyName)
  if (_.isNil(account)) {
    return null
  }
  return JSON.parse(account)
}

export function updateAccount(data) {
  const account = getAccount() || {}
  localStorage.setItem(keyName, JSON.stringify(_.merge(account, data)))
}

export function removeAccount() {
  return localStorage.removeItem(keyName)
}