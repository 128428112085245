<template>
  <modal
      v-if="isShow"
      :isClosable="params.isCancelable"
      @close="cancel"
  >
    <div class="pt-3 pb-3">
      <span>{{params.title}}</span>

      <div class="row">
        <div class="col-24">
          <template v-for="(input, index) of params.inputs">
            <div class="form-group d-flex align-items-center"
                 v-if="input.type === 'toggle-button'"
                 :key="index"
            >
              <label class="text-muted" :for="input.id">{{input.label}}</label>
              <ToggleButton class="ml-2"
                            v-model="inputValues[input.id]"
                            :sync="false"
                            :name="input.name"
                            :id="input.id"
              ></ToggleButton>
            </div>
            <div class="form-group"
                 v-else
                 :key="index"
            >
              <label class="text-muted" :for="input.id">{{input.label}}</label>
              <input
                  :type="input.type"
                  :name="input.name"
                  :id="input.id"
                  class="form-control"
                  v-focus
                  v-model="inputValues[input.id]"
              >
            </div>
          </template>
        </div>
      </div>

      <div class="row" :class="{ 'pt-3': !params.inputs.length }">
        <div class="col-12">
          <button type="button" class="btn btn-block btn-bc btn-gray-100"
                  @click="cancel"
                  v-if="params.isCancelable"
          >
            Cancel
          </button>
        </div>
        <div :class="{ 'col-12': params.isCancelable, 'col-24': !params.isCancelable }">
          <button type="submit" class="btn btn-block btn-bc btn-purple-900" @click="ok">Ok</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import focus from '@/directives/focus'
import Modal from '@/components/common/Modal'
import ToggleButton from '@/components/common/ToggleButton'
import { events } from './events'
export default {
  components: {
    Modal,
    ToggleButton,
  },
  directives: {
    focus,
  },
  data() {
    return {
      isShow: false,
      inputValues: {},
      params: this.defaultParams(),
    }
  },
  methods: {
    defaultParams() {
      return {
        title: '',
        isCancelable: true,
        inputs: []
      }
    },
    resetState() {
      this.inputValues = {}
      this.params = this.defaultParams()
    },
    ok(e) {
      e.preventDefault()
      this.isShow = false
      this.handleCallback(true)
      this.resetState()
    },
    cancel() {
      this.isShow = false
      this.handleCallback(false)
      this.resetState()
    },
    open(params) {
      this.params = Object.assign(this.params, params)
      for (const input of this.params.inputs) {
        let d = Object.assign({}, input)
        this.inputValues[d.id] = d.value
      }
      this.isShow = true
    },
    handleCallback(confirm) {
      if (this.params.callback) {
        this.params.callback(confirm, { inputValues: this.inputValues })
      }
    }
  },
  mounted() {
    events.$on('open', this.open)
    events.$on('close', this.cancel)
  }
}
</script>

<style scoped lang="scss">
</style>
