import config from '@/config'
import { getWsAuth } from '@/utils/credentials.utils'
import WsClient from '@/clients/ws.client'

const { usersWsUrl } = config

class UsersClient extends WsClient {

  get wsUrl() {
    return usersWsUrl
  }

  get wsUrlDisplay() {
    return '@bearcat/users/ws'
  }

  get credentials() {
    return getWsAuth()
  }

  constructor() {
    super({ autoConnect: false })
    this.on('error', this.onError)
    this.on('banned', this.onBanned)
  }

  onError(d) {
    const { error } = d
    switch (error) {
      case 'SocketBanned':
        this.onBanned()
        break
    }
  }

  onBanned() {
    console.error(`${this.wsUrlDisplay} banned`)
    this.authorized = false
  }

}

export default new UsersClient()
