export default {
  namespaced: true,
  state: {
    isLoading: false
  },
  actions: {},
  mutations: {
    start(state) {
      state.isLoading = true
    },
    finish(state) {
      state.isLoading = false
    }
  }
}
