import _ from 'lodash'
import { getAccount, updateAccount, removeAccount } from '@/utils/account.utils'

const {
  id, username, avatarVersion, backgroundVersion,
  streamAudioVolume, muteStreamAudioVolume,
  alertAudioVolume, muteAlertAudioVolume, staff, premium,
} = getAccount() || {}

export default {
  namespaced: true,
  state: {
    id,
    username,
    avatarVersion,
    backgroundVersion,
    staff: _.isNil(staff) ? null : staff,
    room: null,
    premium: _.isNil(premium) ? null : premium,
    streamAudioVolume: _.isNil(streamAudioVolume) ? 0.5 : streamAudioVolume,
    muteStreamAudioVolume: _.isNil(muteStreamAudioVolume) ? false : muteStreamAudioVolume,
    alertAudioVolume: _.isNil(alertAudioVolume) ? 0.5 : alertAudioVolume,
    muteAlertAudioVolume: _.isNil(muteAlertAudioVolume) ? false : muteAlertAudioVolume,
  },
  actions: {},
  mutations: {
    setAccount(state, {
      id, username, avatarVersion, backgroundVersion, staff, room, premium,
      streamAudioVolume, muteStreamAudioVolume,
      alertAudioVolume, muteAlertAudioVolume,
    }) {
      if (!_.isUndefined(id)) {
        state.id = id
      }
      if (!_.isUndefined(username)) {
        state.username = username
      }
      if (!_.isUndefined(avatarVersion)) {
        state.avatarVersion = avatarVersion
      }
      if (!_.isUndefined(backgroundVersion)) {
        state.backgroundVersion = backgroundVersion
      }
      if (!_.isUndefined(staff)) {
        state.staff = staff
      }
      if (!_.isUndefined(room)) {
        state.room = room
      }
      if (!_.isUndefined(premium)) {
        state.premium = premium
      }
      if (!_.isUndefined(streamAudioVolume)) {
        state.streamAudioVolume = streamAudioVolume
      }
      if (!_.isUndefined(muteStreamAudioVolume)) {
        state.muteStreamAudioVolume = muteStreamAudioVolume
      }
      if (!_.isUndefined(alertAudioVolume)) {
        state.alertAudioVolume = alertAudioVolume
      }
      if (!_.isUndefined(muteAlertAudioVolume)) {
        state.muteAlertAudioVolume = muteAlertAudioVolume
      }
      updateAccount({
        id, username, avatarVersion, backgroundVersion, staff,
        streamAudioVolume, muteStreamAudioVolume,
        alertAudioVolume, muteAlertAudioVolume,
        premium,
      })
    },
    resetState(state) {
      state.id = null
      state.username = null
      state.avatarVersion = null
      state.backgroundVersion = null
      state.staff = null
      state.room = null
      state.streamAudioVolume = 0.5
      state.muteStreamAudioVolume = false
      state.alertAudioVolume = 0.5
      state.muteAlertAudioVolume = false
      state.premium = null
      removeAccount()
    }
  }
}
