import _ from 'lodash'

const keyName = 'credentials'

export function getCredentials() {
  const credentials = localStorage.getItem(keyName)
  if (_.isNil(credentials)) {
    return null
  }
  const [ accessKeyId, accessKey, sessionId, renewalKey ] = credentials.split(':')
  return { accessKeyId, accessKey, sessionId, renewalKey }
}

export function updateCredentials({ accessKeyId, accessKey, sessionId, renewalKey }) {
  const credentials = getCredentials() || {}
  if (_.isUndefined(accessKeyId)) {
    accessKeyId = credentials.accessKeyId
  }
  if (_.isUndefined(accessKey)) {
    accessKey = credentials.accessKey
  }
  if (_.isUndefined(sessionId)) {
    sessionId = credentials.sessionId
  }
  if (_.isUndefined(renewalKey)) {
    renewalKey = credentials.renewalKey
  }
  return localStorage.setItem(keyName, [ accessKeyId, accessKey, sessionId, renewalKey ].join(':'))
}

export function removeCredentials() {
  return localStorage.removeItem(keyName)
}

export function hasCredentials() {
  return !_.isNil(localStorage.getItem(keyName))
}

export function getApiAuth() {
  const credentials = getCredentials() || {}
  return {
    username: credentials.accessKeyId,
    password: credentials.accessKey,
  }
}

export function getRenewalApiAuth() {
  const credentials = getCredentials() || {}
  return {
    username: credentials.sessionId,
    password: credentials.renewalKey,
  }
}

export function getWsAuth() {
  const credentials = getCredentials() || {}
  return _.pick(credentials, ['accessKeyId', 'accessKey'])
}
