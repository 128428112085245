export default {
  appUrl: process.env.VUE_APP_URL,
  apiUrl: process.env.VUE_APP_API_URL,
  usersWsUrl: process.env.VUE_APP_USERS_WS_URL,
  membersWsUrl: process.env.VUE_APP_MEMBERS_WS_URL,
  staticUrl: process.env.VUE_APP_STATIC_URL,
  giphyApiKey: process.env.VUE_APP_GIPHY_API_KEY,
  recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  paypalPlanId: process.env.VUE_APP_PAYPAL_PLAN_ID,
}
