<template>
  <loading class="loading"
           :active.sync="isLoading"
           :opacity="opacity"
           :loader="loader"
           :color="color"
           :z-index="zIndex"
           :background-color="backgroundColor"
           :is-full-page="isFullPage"
  ></loading>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading
  },
  props: {
    isLoading: Boolean,
    loader: {
      type: String,
      default: 'spinner'
    },
    isFullPage: {
      type: Boolean,
      default: false
    },
    opacity: {
      type: Number,
      default: 1
    },
    color: {
      type: String,
      default: '#dbdbdb'
    },
    backgroundColor: {
      type: String,
      default: '#1a1a1d'
    },
    zIndex: {
      type: Number,
      default: 1020
    },
  }
}
</script>

<style scoped lang="scss">
.loading {
  outline: none;
}
</style>
