import store from '@/store'

export default {
  inserted(el) {
    if (!store.state.loading.isLoading) {
      el.focus()
    } else {
      const unwatch = store.watch((state) => state.loading.isLoading, (isLoading) => {
        if (!isLoading) {
          el.focus()
          unwatch()
        }
      })
    }
  }
}
