export default {
  namespaced: true,
  state: {
    notificationBubble: { count: 0, silent: true },
    messageBubble: { count: 0, silent: true },
    friendBubble: { count: 0, silent: true }
  },
  actions: {},
  mutations: {
    setNotificationBubble(state, { count, silent }) {
      state.notificationBubble = { count, silent }
    },
    incNotificationBubble(state, { silent }) {
      const count = state.notificationBubble.count + 1
      state.notificationBubble = { count, silent }
    },
    setMessageBubble(state, { count, silent }) {
      state.messageBubble = { count, silent }
    },
    incMessageBubble(state, { silent }) {
      const count = state.messageBubble.count + 1
      state.messageBubble = { count, silent }
    },
    setFriendBubble(state, { count, silent }) {
      state.friendBubble = { count, silent }
    },
    incFriendBubble(state, { silent }) {
      const count = state.friendBubble.count + 1
      state.friendBubble = { count, silent }
    },
    resetState(state) {
      state.notificationBubble = { count: 0, silent: true }
      state.messageBubble = { count: 0, silent: true }
      state.friendBubble = { count: 0, silent: true }
    }
  }
}
