import _ from 'lodash'

const keyPrefix = 'common'
const roomCategoriesKey = [ keyPrefix, 'roomCategories' ].join(':')
const roomQualitiesKey = [ keyPrefix, 'roomQualities' ].join(':')
const roomCountKey = [ keyPrefix, 'roomCount' ].join(':')
const roomVpnsKey = [ keyPrefix, 'roomVpns' ].join(':')

export function getRoomCategories() {
  const roomCategories = localStorage.getItem(roomCategoriesKey)
  if (_.isNil(roomCategories)) {
    return null
  }
  return JSON.parse(roomCategories)
}

export function setRoomCategories(roomCategories) {
  localStorage.setItem(roomCategoriesKey, JSON.stringify(roomCategories))
}

export function removeRoomCategories() {
  return localStorage.removeItem(roomCategoriesKey)
}

export function getRoomQualities() {
  const roomQualities = localStorage.getItem(roomQualitiesKey)
  if (_.isNil(roomQualities)) {
    return null
  }
  return JSON.parse(roomQualities)
}

export function setRoomQualities(roomQualities) {
  localStorage.setItem(roomQualitiesKey, JSON.stringify(roomQualities))
}

export function removeRoomQualities() {
  return localStorage.removeItem(roomQualitiesKey)
}

export function getRoomCount() {
  const roomCount = localStorage.getItem(roomCountKey)
  if (_.isNil(roomCount)) {
    return null
  }
  return JSON.parse(roomCount)
}

export function setRoomCount(roomCount) {
  localStorage.setItem(roomCountKey, JSON.stringify(roomCount))
}

export function removeRoomCount() {
  return localStorage.removeItem(roomCountKey)
}

export function setRoomVpns(roomVpns) {
  localStorage.setItem(roomVpnsKey, JSON.stringify(roomVpns))
}

export function removeRoomVpns() {
  return localStorage.removeItem(roomVpnsKey)
}

export function getRoomVpns() {
  const roomVpns = localStorage.getItem(roomVpnsKey)
  if (_.isNil(roomVpns)) {
    return null
  }
  return JSON.parse(roomVpns)
}