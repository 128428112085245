import EventEmitter from 'events'
import _ from 'lodash'

class WsClient extends EventEmitter {

  get wsUrl() {
    throw new Error('Missing class property: wsUrl')
  }

  get wsUrlDisplay() {
    throw new Error('Missing class property: wsUrlDisplay')
  }

  get credentials() {
    throw new Error('Missing class property: credentials')
  }

  get defaultOptions() {
    return {
      autoReconnect: true,
      autoConnect: true,
    }
  }

  get connected() {
    if (_.isNil(this.socket)) {
      return false
    }
    return this.socket.readyState === 1
  }

  constructor(options) {
    super()
    const { autoConnect, autoReconnect } = _.merge(this.defaultOptions, options)
    this.socket = null
    this.authorized = false
    this.defaultAutoReconnect = autoReconnect
    this.autoReconnectTimer = null
    if (autoConnect) {
      this.connect()
    }
  }

  disconnect() {
    this.clearAutoReconnectTimer()
    this.autoReconnect = false
    if (!_.isNil(this.socket)) {
      this.socket.close()
      this.socket = null
    }
    this.authorized = false
  }

  connect() {
    this.disconnect()
    this.autoReconnect = this.defaultAutoReconnect
    this.socket = new WebSocket(this.wsUrl)
    this.socket.onerror = () => {
      console.log(`Error during connection to ${this.wsUrlDisplay}`)
    }
    this.socket.onclose = () => {
      console.log(`Disconnected from ${this.wsUrlDisplay}`)
      this.authorized = false
      if (this.autoReconnect) {
        this.reconnectAfterPause()
      }
    }
    this.socket.onopen = () => {
      console.log(`Connected to ${this.wsUrlDisplay}`)
      this.authorized = false
      this.send({
        t: 'authorization',
        d: this.credentials
      })
    }
    this.socket.onmessage = (payload) => {
      this.handleMessage(JSON.parse(payload.data))
    }
  }

  clearAutoReconnectTimer() {
    if (!_.isNil(this.autoReconnectTimer)) {
      clearTimeout(this.autoReconnectTimer)
      this.autoReconnectTimer = null
      console.log(`Reconnect to ${this.wsUrlDisplay} canceled`)
    }
  }

  reconnectAfterPause() {
    this.clearAutoReconnectTimer()
    console.log(`Reconnect to ${this.wsUrlDisplay} after 5 seconds...`)
    this.autoReconnectTimer = setTimeout(() => {
      this.connect()
    }, 5000)
  }

  send(msg) {
    try {
      this.socket.send(JSON.stringify(msg))
    } catch (e) {
      console.error(e)
    }
  }

  handleMessage(msg) {
    const { t, d } = msg
    switch (t) {
      case 'authorized':
        this.handleAuthorized()
        break
      case 'error':
        this.handleError(d)
        break
      default:
        this.handleAuthorizedMessage(msg)
    }
  }

  handleAuthorizedMessage(msg) {
    const { t, d } = msg
    this.emit(t, d)
  }

  handleError(d) {
    const { error } = d
    switch (error) {
      case 'SocketUnauthorized':
        console.error(`${this.wsUrlDisplay} unauthorized`)
        this.authorized = false
        this.emit('error', d)
        break
      default:
        console.error(`${this.wsUrlDisplay} error: ${error}`)
        this.emit('error', d)
    }
  }

  handleAuthorized() {
    console.log(`${this.wsUrlDisplay} authorized`)
    this.authorized = true
    this.emit('authorized')
  }

}

export default WsClient
