<template>
  <div id="app">
    <!-- router -->
    <router-view/>
    <!-- progressbar -->
    <vue-progress-bar></vue-progress-bar>
    <!-- loading -->
    <Loading :isLoading="isLoading" :backgroundColor="'#1a1a1d'" :isFullPage="true" :zIndex="1040"></Loading>
    <!-- confirm dialog -->
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import config from '@/config'
import Loading from '@/components/common/Loading.vue'
import ConfirmDialog from '@/components/presets/confirm-dialog/ConfirmDialog'
import { getApiAuth, getRenewalApiAuth, updateCredentials } from '@/utils/credentials.utils'
import usersClient from '@/clients/users.client'

const { apiUrl } = config

export default {
  components: {
    Loading,
    ConfirmDialog,
  },
  computed: {
    ...mapState('loading', {
      isLoading: state => state.isLoading
    })
  },
  data() {
    return {
      isRedirectByError: false,
    }
  },
  created() {
    this.$router.beforeResolve(this.routerBeforeResolve)
    this.$router.afterEach(this.routerAfterEach)
    usersClient.on('banned', async () => {
      await this.$router.push({ name: 'BanPage' })
    })
  },
  methods: {
    getBanPageQuery(metadata) {
      const { ttl, reason } = metadata
      return  _.pickBy({
        idBanTtl: ttl.idBanTtl,
        ipBanTtl: ttl.ipBanTtl,
        idBanReason: reason.idBanReason,
        ipBanReason: reason.ipBanReason,
      }, _.identity)
    },
    redirectByError(next, opts, from) {
      this.isRedirectByError = (
          from.name !== opts.name ||
          (!_.isNil(opts.query) && !_.isEmpty(opts.query))
      )
      return next(opts)
    },
    async routerAfterEach(to) {
      this.isRedirectByError = false
      const toMeta = this.getMeta(to.meta)
      if (toMeta.autoFinishLoading) {
        this.$nextTick(() => {
          this.$Progress.finish()
          this.$store.commit('loading/finish')
        })
      }
      this.$gtag.pageview(to.path)
    },
    async routerBeforeResolve(to, from, next) {
      const toMeta = this.getMeta(to.meta)

      // start loading
      if (!this.isRedirectByError) {
        if (_.isNil(from.name)) {
          this.$store.commit('loading/start')
        }
        this.$Progress.start()
      }

      // session status check
      try {
        await this.$http.get(apiUrl + '/account/session/status', {
          auth: getApiAuth(),
          params: {
            checkCredentials: toMeta.checkCredentials,
            check2Fa: toMeta.check2Fa,
            checkUserBan: toMeta.checkUserBan,
            checkUserVerification: toMeta.checkUserVerification,
            checkPremium: toMeta.checkPremium,
          }
        })
      } catch (e) {
        if (!_.isNil(e.response)) {
          const respResult = e.response.data.result
          console.error(respResult.error)
          if (respResult.error === 'UserBanned') {
            this.$Progress.fail()
            return this.redirectByError(next, { name: 'BanPage' }, from)
          } else if (respResult.error === 'AccessKeyExpired') {
            if (!await this.renewSession()) {
              this.$Progress.fail()
              return this.redirectByError(next, { name: 'SigninPage' }, from)
            }
          } else if (respResult.error === 'SessionExpired') {
            this.$Progress.fail()
            return this.redirectByError(next, { name: 'SigninPage' }, from)
          } else if (respResult.error === 'SessionUnverified') {
            this.$Progress.fail()
            return this.redirectByError(next, { name: '2FaPage' }, from)
          } else if (respResult.error === 'AccountUnverified') {
            this.$Progress.fail()
            return this.redirectByError(next, { name: 'VerificationPage' }, from)
          }
        } else {
          this.$Progress.fail()
          throw e
        }
      }

      // handle users client connection
      if (toMeta.needUsersWsClient) {
        if (!usersClient.connected) {
          usersClient.connect()
        }
      } else {
        usersClient.disconnect()
      }

      next()
    },
    async renewSession() {
      let response
      try {
        response = await this.$http.post(apiUrl + '/account/session/renewal', {}, {
          auth: getRenewalApiAuth(),
        })
      } catch (e) {
        console.error(e)
        this.$Progress.fail()
        return false
      }
      updateCredentials(response.data.result)
      return true
    },
    getMeta(meta) {
      return _.merge({
        checkUserBan: true,
        check2Fa: true,
        checkPremium: false,
        checkCredentials: true,
        checkUserVerification: false,
        autoFinishLoading: true,
        needUsersWsClient: false,
      }, meta)
    },
  }
}
</script>

<style lang="scss">
@import '~@/styles/app';
</style>
