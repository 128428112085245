<template>
  <toggle-button
      v-bind:value="value"
      @change="onChange"
      :color="color"
      :switch-color="switchColor"
      :sync="sync"
      :disabled="disabled"
  ></toggle-button>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    sync: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      color: {
        checked: '#9236e1',
        unchecked: '#29292b',
      },
      switchColor: '#dbdbdb',
    }
  },
  methods: {
    onChange(data) {
      this.$emit('input', data.value)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
