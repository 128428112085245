export default {
  namespaced: true,
  state: {
    transition: false,
    current: null
  },
  actions: {},
  mutations: {
    show(state, sidebar) {
      state.current = sidebar
    },
    hide(state) {
      state.current = null
    },
    toggle(state, sidebar) {
      if (state.current && state.current.id === sidebar.id) {
        switch (sidebar.id) {
          case 'profile':
            if (state.current.userId === sidebar.userId) {
              state.current = null
            } else {
              state.current = sidebar
            }
            break
          default:
            state.current = null
        }

      } else {
        state.current = sidebar
      }
    },
    transition(state, transition) {
      state.transition = transition
    },
    resetState(state) {
      state.transition = false
      state.current = null
    }
  }
}
