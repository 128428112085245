import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    ready: null,
    inactive: null,
    progress: null,
    roomId: null,
    isPrivate: null,
    lockInteraction: true,
    streamBufferSize: null,
    memberCount: null,
    membersClient: null,
    host: null,
    owner: null,
    blacklistCount: null,
    fullscreen: null,
    theater: null,
    voiceChatConnected: null,
    voiceChatMicroMuted: null,
    voiceChatDeafened: null,
    voiceChatParticipantCount: null,
    voiceChatProcessing: null,
    showPlay: null,
    streamReady: null,
    videoCodec: null,
    channelTurnUrl: null,
    channelTurnUser: null,
    channelTurnPass: null,
    voiceChatTurnUrl: null,
    voiceChatTurnUser: null,
    voiceChatTurnPass: null,
  },
  actions: {},
  mutations: {
    setRoom(state, {
      roomId, ready, inactive, isPrivate, progress, lockInteraction, streamBufferSize, memberCount, membersClient,
      host, owner, blacklistCount, fullscreen, theater,
      voiceChatConnected, voiceChatMicroMuted, voiceChatDeafened, voiceChatParticipantCount, voiceChatProcessing,
      showPlay, streamReady, videoCodec,
      channelTurnUrl, channelTurnUser, channelTurnPass,
      voiceChatTurnUrl, voiceChatTurnUser, voiceChatTurnPass,
    }) {
      if (!_.isUndefined(roomId)) {
        state.roomId = roomId
      }
      if (!_.isUndefined(isPrivate)) {
        state.isPrivate = isPrivate
      }
      if (!_.isUndefined(ready)) {
        state.ready = ready
      }
      if (!_.isUndefined(inactive)) {
        state.inactive = inactive
      }
      if (!_.isUndefined(progress)) {
        state.progress = progress
      }
      if (!_.isUndefined(lockInteraction)) {
        state.lockInteraction = lockInteraction
      }
      if (!_.isUndefined(streamBufferSize)) {
        state.streamBufferSize = streamBufferSize
      }
      if (!_.isUndefined(memberCount)) {
        state.memberCount = memberCount
      }
      if (!_.isUndefined(membersClient)) {
        state.membersClient = membersClient
      }
      if (!_.isUndefined(host)) {
        state.host = host
      }
      if (!_.isUndefined(owner)) {
        state.owner = owner
      }
      if (!_.isUndefined(blacklistCount)) {
        state.blacklistCount = blacklistCount
      }
      if (!_.isUndefined(fullscreen)) {
        state.fullscreen = fullscreen
      }
      if (!_.isUndefined(theater)) {
        state.theater = theater
      }
      if (!_.isUndefined(voiceChatConnected)) {
        state.voiceChatConnected = voiceChatConnected
      }
      if (!_.isUndefined(voiceChatMicroMuted)) {
        state.voiceChatMicroMuted = voiceChatMicroMuted
      }
      if (!_.isUndefined(voiceChatDeafened)) {
        state.voiceChatDeafened = voiceChatDeafened
      }
      if (!_.isUndefined(voiceChatParticipantCount)) {
        state.voiceChatParticipantCount = voiceChatParticipantCount
      }
      if (!_.isUndefined(voiceChatProcessing)) {
        state.voiceChatProcessing = voiceChatProcessing
      }
      if (!_.isUndefined(showPlay)) {
        state.showPlay = showPlay
      }
      if (!_.isUndefined(streamReady)) {
        state.streamReady = streamReady
      }
      if (!_.isUndefined(videoCodec)) {
        state.videoCodec = videoCodec
      }
      if (!_.isUndefined(channelTurnUrl)) {
        state.channelTurnUrl = channelTurnUrl
      }
      if (!_.isUndefined(channelTurnUser)) {
        state.channelTurnUser = channelTurnUser
      }
      if (!_.isUndefined(channelTurnPass)) {
        state.channelTurnPass = channelTurnPass
      }
      if (!_.isUndefined(voiceChatTurnUrl)) {
        state.voiceChatTurnUrl = voiceChatTurnUrl
      }
      if (!_.isUndefined(voiceChatTurnUser)) {
        state.voiceChatTurnUser = voiceChatTurnUser
      }
      if (!_.isUndefined(voiceChatTurnPass)) {
        state.voiceChatTurnPass = voiceChatTurnPass
      }
    },
    resetState(state) {
      state.roomId = null
      state.ready = null
      state.inactive = null
      state.progress = null
      state.isPrivate = null
      state.lockInteraction = true
      state.streamBufferSize = null
      state.memberCount = null
      state.membersClient = null
      state.host = null
      state.owner = null
      state.blacklistCount = null
      state.fullscreen = null
      state.theater = null
      state.voiceChatConnected = null
      state.voiceChatMicroMuted = null
      state.voiceChatDeafened = null
      state.voiceChatParticipantCount = null
      state.voiceChatProcessing = null
      state.showPlay = null
      state.streamReady = null
      state.videoCodec = null
      state.channelTurnUrl = null
      state.channelTurnUser = null
      state.channelTurnPass = null
      state.voiceChatTurnUrl = null
      state.voiceChatTurnUser = null
      state.voiceChatTurnPass = null
    }
  }
}
