import {
  setRoomCategories,
  setRoomQualities,
  getRoomCategories,
  getRoomQualities,
  removeRoomCategories,
  removeRoomQualities,
  setRoomCount,
  getRoomCount,
  removeRoomCount,
  getRoomVpns,
  setRoomVpns,
  removeRoomVpns,
} from '@/utils/common.utils'

const roomCategories = getRoomCategories() || []
const roomQualities = getRoomQualities() || []
const roomCount = getRoomCount() || []
const roomVpns = getRoomVpns() || []

export default {
  namespaced: true,
  state: {
    roomCategories,
    roomQualities,
    roomCount,
    roomVpns,
  },
  actions: {},
  mutations: {
    setRoomVpns(state, roomVpns) {
      state.roomVpns = roomVpns
      setRoomVpns(roomVpns)
    },
    setRoomCategories(state, roomCategories) {
      state.roomCategories = roomCategories
      setRoomCategories(roomCategories)
    },
    setRoomQualities(state, roomQualities) {
      state.roomQualities = roomQualities
      setRoomQualities(roomQualities)
    },
    setRoomCount(state, roomCount) {
      state.roomCount = roomCount
      setRoomCount(roomCount)
    },
    resetState(state) {
      state.roomCategories = []
      state.roomQualities = []
      state.roomCount = []
      state.roomVpns = []
      removeRoomCategories()
      removeRoomQualities()
      removeRoomCount()
      removeRoomVpns()
    }
  }
}
