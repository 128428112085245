import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'WelcomePage',
    component: () => import('@/views/welcome/WelcomePage'),
    meta: {
      autoFinishLoading: true,
      needUsersWsClient: false,
      checkCredentials: false,
      check2Fa: false,
    }
  },
  {
    path: '/rooms',
    name: 'HomePage',
    component: () => import('@/views/home/HomePage'),
    meta: {
      autoFinishLoading: false,
      needUsersWsClient: true,
    }
  },
  {
    path: '/trending',
    name: 'TrendingPage',
    component: () => import('@/views/trending/TrendingPage'),
    meta: {
      autoFinishLoading: false,
      needUsersWsClient: true,
    }
  },
  {
    path: '/categories/:categoryId',
    name: 'CategoryPage',
    component: () => import('@/views/category/CategoryPage'),
    meta: {
      autoFinishLoading: false,
      needUsersWsClient: true,
    }
  },
  {
    path: '/rooms/:roomId',
    name: 'RoomPage',
    component: () => import('@/views/room/RoomPage'),
    meta: {
      autoFinishLoading: false,
      needUsersWsClient: true,
    }
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: () => import('@/views/signup/SignupPage'),
    meta: {
      checkCredentials: false,
      check2Fa: false,
    }
  },
  {
    path: '/signin',
    name: 'SigninPage',
    component: () => import('@/views/signin/SigninPage'),
    meta: {
      checkCredentials: false,
      check2Fa: false,
    }
  },
  {
    path: '/recovery',
    name: 'RecoveryPage',
    component: () => import('@/views/recovery/RecoveryPage'),
    meta: {
      checkCredentials: false,
      check2Fa: false,
    }
  },
  {
    path: '/verification',
    name: 'VerificationPage',
    component: () => import('@/views/verification/VerificationPage'),
    meta: {
      needUsersWsClient: true,
    }
  },
  {
    path: '/2fa',
    name: '2FaPage',
    component: () => import('@/views/2fa/2FaPage'),
    meta: {
      check2Fa: false,
    }
  },
  {
    path: '/ban',
    name: 'BanPage',
    component: () => import('@/views/ban/BanPage'),
    meta: {
      checkCredentials: true,
      checkUserBan: false,
      check2Fa: false,
      autoFinishLoading: false,
    }
  },
  {
    path: '/room-creation',
    name: 'RoomCreationPage',
    component: () => import('@/views/room-creation/RoomCreationPage'),
    meta: {
      checkUserVerification: true,
      needUsersWsClient: true,
    }
  },
  {
    path: '/premium',
    name: 'PremiumPage',
    component: () => import('@/views/premium/PremiumPage'),
    meta: {
      needUsersWsClient: true,
    }
  },
  {
    path: '/terms',
    name: 'TermsOfServicePage',
    component: () => import('@/views/legal/TermsOfServicePage'),
    meta: {
      checkCredentials: false,
      check2Fa: false,
    }
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicyPage',
    component: () => import('@/views/legal/PrivacyPolicyPage'),
    meta: {
      checkCredentials: false,
      check2Fa: false,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
