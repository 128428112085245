import Vue from 'vue'
import Vuex from 'vuex'
import loading from '@/store/loading'
import sidebar from '@/store/sidebar'
import bubble from '@/store/bubble'
import account from '@/store/account'
import room from '@/store/room'
import common from '@/store/common'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    loading,
    sidebar,
    bubble,
    account,
    room,
    common,
  }
})
