<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" v-click-outside="close">
        <div class="container-fluid">
          <form>
            <div class="row">
              <div class="col-24">
                <slot>
                  default body
                </slot>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isClosable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    close() {
      if (this.isClosable) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  display: table;
  background-color: rgba(0, 0, 0, .5);
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 360px;
  max-width: 100vw;
  margin: 0 auto;
  background-color: $bc-gray-800;
}
</style>
